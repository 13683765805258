<!--
 * @Author: your name
 * @Date: 2021-07-30 15:38:22
 * @LastEditTime: 2021-12-01 10:41:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\activity\detail.vue
-->
<template>
  <div class="detail">
    <div class="container">
      <aside>
        <div class="initiator">
          <div class="title flex-h flex-vc">
            <img src="@/assets/img/ren.png" />
            <span>活动发起人</span>
          </div>
          <div class="teamInfo" @click="goServeDetail">
            <img :src="queueLogo" />
            <span>{{ queueName }}</span>
          </div>
        </div>
        <div class="contact">
          <div class="title flex-h flex-vc">
            <img src="@/assets/img/phone.png" />
            <span>联系方式</span>
          </div>
          <p>负责人：{{ principal }}</p>
          <p>联系电话：{{ contactNumber }}</p>
          <p>联系邮箱：{{ contactEmail }}</p>
          <p>联系地址：{{ contactAddr }}</p>
        </div>
      </aside>
      <div class="main">
        <h1>
          {{ activityName }}
        </h1>
        <section class="flex-h">
          <img class="cover" :src="activityImage" />
          <div class="detailRight">
            <p>
              活动时间：{{ $formatDate(startTime, "{y}/{m}/{d} {h}:{i}") }} -
              {{ $formatDate(endTime, "{y}/{m}/{d} {h}:{i}") }}
            </p>
            <p>服务时长：{{ longTime == null ? 0 : longTime }}小时</p>
            <p>活动地点：{{ activityAddress }}</p>
            <p>服务类型：{{ typeName }}</p>
            <p>计划招募：{{ recruitNum }}人</p>
            <div class="flex-h flex-vc">
              <!-- 超过报名时间或者活动已经过期 -->
              <div
                v-if="
                  currentDate > applicationDeadline || currentDate > endTime
                "
              >
                <div class="btn2">我要报名</div>
              </div>
              <!-- 能加入 -->
              <div v-else>
                <div
                  class="btn2"
                  v-if="activityStatus == 0 || activityStatus == 1"
                  @click="noSign"
                >
                  已报名
                </div>
                <div
                  class="btn1"
                  @click="goSign"
                  v-if="
                    activityStatus == 2 ||
                    activityStatus == 3 ||
                    activityStatus == 4
                  "
                >
                  <p style="padding: 3px">我要报名</p>
                  <p>剩余{{ applicationDeadline | time }}截止</p>
                </div>
              </div>

              <div class="focus flex-h flex-vc flex-hc" @click="changeFocus">
                <img src="@/assets/img/xin2.png" v-if="attention == 0" />
                <img src="@/assets/img/xin1.png" v-if="attention == 1" />
                <span>关注</span>
              </div>
            </div>
          </div>
        </section>
        <div class="content">
          <div class="contentDetail flex-h flex-vc">
            <span></span>
            <p>活动详情</p>
          </div>
          <p class="desc">{{ activityInfo }}</p>
        </div>
      
        <div class="content" v-if="currentDate > endTime">
          <div class="contentDetail flex-h flex-vc">
            <span></span>
            <p>活动图片</p>
          </div>
          <img class="confirmImg" v-for="(item,index) in confirmImage" :key="index" :src="item" />
        </div>
      </div>
      <comment />
    </div>
  </div>
</template>
<script>
import comment from "./components/comment.vue";
import {
  queueContacts,
  activityInfo,
  attention,
  applyActivity,
  cancelActivity,
} from "@/api/index";
export default {
  data() {
    return {
      applyId: "",
      currentDate: parseInt(new Date().getTime() / 1000),
      attention: 1,
      sign: false,
      activityStatus: null,
      activityImage: "",
      activityName: "",
      startTime: "",
      endTime: "",
      longTime: 0,
      activityAddress: "",
      applicationDeadline: 0,
      recruitNum: 0,
      activityInfo: "",
      typeName: "",
      queueName: "",
      queueId: "",
      queueStatus: "",
      queueLogo: "",
      principal: "",
      contactNumber: "",
      contactEmail: "",
      contactAddr: "",
      confirmImage:[]
    };
  },
  components: {
    comment,
  },
  methods: {
    goServeDetail() {
      if (this.queueStatus == 2) {
        this.$message({
          message: "该服务队已解散",
          type: "warning",
        });
      }
      if (this.queueStatus == 3) {
        this.$message({
          message: "该服务队已关闭",
          type: "warning",
        });
      }

      if (this.queueStatus == 1) {
        this.$router.push({ name: "serveDetail", query: { id: this.queueId } });
      }
    },
    changeFocus() {
      let status = this.attention == 1 ? 0 : 1;
      attention({
        attentionId: this.$route.query.id,
        status: status,
        type: 0,
      }).then((res) => {
        if (status == 1) {
          this.$message({
            type: "success",
            message: "取消关注成功!",
          });
        }
        if (status == 0) {
          this.$message({
            type: "success",
            message: "关注成功!",
          });
        }
        this.getData();
      });
    },
    getData() {
      activityInfo({
        id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        this.applyId = res.data.applyId;
        this.activityImage = res.data.activityImage;
        this.activityName = res.data.activityName;
        this.startTime = res.data.startTime;
        this.endTime = res.data.endTime;
        this.longTime = res.data.longTime;
        this.activityAddress = res.data.activityAddress;
        this.recruitNum = res.data.recruitNum;
        this.activityInfo = res.data.activityInfo;
        this.activityStatus = res.data.activityStatus;
        this.attention = res.data.attention;
        this.applicationDeadline = res.data.applicationDeadline;
        this.typeName = res.data.typeName;
        this.queueLogo = res.data.queueLogo;
        this.queueName = res.data.queueName;
        this.queueId = res.data.queueId;
        this.queueStatus = res.data.queueStatus;
        this.confirmImage=res.data.confirmImage || []
      });
    },
    queueContacts() {
      queueContacts({
        id: this.$route.query.id,
        type: 0,
      }).then((res) => {
        this.principal = res.data.principal;
        this.contactNumber = res.data.contactNumber;
        this.contactEmail = res.data.contactEmail;
        this.contactAddr = res.data.contactAddr;
      });
    },
    goSign() {
      this.$confirm("确定报名参与该活动吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        applyActivity({
          activityId: this.$route.query.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getData();
        });
      });
    },
    noSign() {
      this.$confirm("确定取消该活动吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelActivity({
          id: this.applyId,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getData();
        });
      });
    },
  },
  filters: {
    time: (value) => {
      let timeS = value - parseInt(new Date().getTime() / 1000);
      console.log(timeS);
      let result = parseInt(timeS);
      var day = Math.floor(result / (24 * 3600)); // Math.floor()向下取整
      var hour = Math.floor((result - day * 24 * 3600) / 3600);
      var minute = Math.floor((result - day * 24 * 3600 - hour * 3600) / 60);
      return day + "天" + hour + "时" + minute + "分";
    },
  },
  created() {
    this.getData();
    this.queueContacts();
  },
};
</script>
<style lang="scss" scoped>
.detail {
  background: #f6f6f6;
  padding: 25px 0;
  white-space: pre-wrap;

  aside {
    float: right;

    .title {
      img {
        width: 30px;
      }

      span {
        font-size: 23px;
        font-family: MicrosoftYaHei;
        color: #ff6e6e;
        margin-left: 11px;
      }
    }

    .initiator {
      width: 294px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 14px;
      margin-bottom: 19px;

      .teamInfo {
        margin-top: 18px;
        cursor: pointer;
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          vertical-align: middle;
        }

        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4f4f4f;
          margin-left: 12px;
        }
      }
    }

    .contact {
      width: 294px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 14px;

      p {
        margin-top: 15px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 25px;
      }
    }
  }

  .main {
    width: 889px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 35px 26px;

    h1 {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 900;
      color: #0c0c0c;
      line-height: 30px;
      margin-bottom: 16px;
    }

    section {
      .cover {
        border-radius: 10px;
        width: 405px;
        height: 227px;
      }

      .detailRight {
        margin-left: 27px;

        p {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #4f4f4f;
          margin-bottom: 11px;
        }

        .btn2 {
          width: 184px;
          height: 47px;
          background: #cacaca;
          border-radius: 24px;
          cursor: pointer;
          text-align: center;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 47px;
        }

        .btn1 {
          cursor: pointer;
          width: 184px;
          height: 47px;
          background: #ff6e6e;
          border-radius: 24px;
          box-sizing: border-box;

          p {
            margin: 0;

            &:nth-of-type(1) {
              text-align: center;
              font-size: 16px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #ffffff;
            }

            &:nth-of-type(2) {
              text-align: center;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
            }
          }
        }

        .focus {
          width: 109px;
          height: 37px;
          background: #f7f7f7;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 44px;

          img {
            width: 25px;
          }

          span {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #4f4f4f;
            margin-left: 5px;
          }
        }
      }
    }

    .content {
      margin-top: 83px;
      word-break: break-all;
      .contentDetail {
        span {
          width: 5px;
          height: 23px;
          background: #ff6e6e;
          border-radius: 100px;
        }

        p {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6e6e;
          margin-left: 7px;
        }
      }

      .desc {
        margin-top: 30px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #808080;
        line-height: 25px;
        word-break: break-all;
      }
      .confirmImg{
        width: 100%;
        margin: 5px 0;
      }
    }
  }
}
</style>