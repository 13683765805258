<!--
 * @Author: your name
 * @Date: 2021-07-31 14:57:40
 * @LastEditTime: 2021-09-27 10:52:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\components\comment.vue
-->
<template>
  <div class="comment">
    <div class="title">
      <img src="../../../assets/img/msg.png" />
      <span>留言咨询（{{ list.length }}）</span>

      <span
        style="float: right; cursor: pointer; font-size: 20px"
        @click="show = true"
        >我要留言</span
      >
    </div>

    <div v-if="show">
      <el-input
        type="textarea"
        :rows="15"
        placeholder="请输入内容"
        maxlength="200"
        show-word-limit
        clearable
        v-model="context"
      >
      </el-input>
      <div class="btn" @click="sub">提交</div>
    </div>
    <ul v-else>
      <li v-for="(item, index) in list" :key="index">
        <div class="header flex-h flex-vc">
          <div class="header-left">
            <img :src="item.headUrl" />
            <span>{{ item.userName }}</span>
          </div>
          <div class="flex-h flex-vc">
            <el-button
              type="text"
              v-if="item.type == 0"
              style="margin-right: 10px"
              @click="del(item)"
              >删除</el-button
            >
            <span>{{ $formatDate(item.releaseTime) }}</span>
          </div>
        </div>
        <div class="content">
          {{ item.context }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { evaluateList, evaluate, evaluateDel } from "@/api/index";
export default {
  data() {
    return {
      context: "",
      show: false,
      list: [],
    };
  },
  methods: {
    sub() {
      if (!this.context) {
        this.$message({
          message: "请输入信息",
          type: "warning",
        });
        return false;
      }
      evaluate({
        context: this.context,
        activityId: this.$route.query.id,
        parentId: 0,
      }).then((res) => {
        this.show = false;
        this.context = "";

        this.getList();
      });
    },
    del(item) {
      this.$confirm("是否删除该评论?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        evaluateDel({
          id: item.evaluateId,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getList();
        });
      });
    },
    getList() {
      evaluateList({
        id: this.$route.query.id,
      }).then((res) => {
        this.list = res.data;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none;
}
.comment {
  width: 889px;

  .title {
    margin-bottom: 19px;
    margin-top: 37px;
    img {
      width: 30px;
      vertical-align: middle;
    }
    span {
      margin-left: 6px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6e6e;
      vertical-align: middle;
    }
  }
  ul {
    border-radius: 8px;
    padding: 11px 22px;
    box-sizing: border-box;
    background: #ffffff;
    margin-bottom: 20px;
    li {
      border-bottom: 1px solid #c0c0c0;
      margin-bottom: 14px;
      &:last-child {
        border-bottom: none;
      }
      .header {
        justify-content: space-between;
        .header-left {
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
          }
          span {
            vertical-align: middle;

            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4f4f4f;
          }
        }
      }
      .content {
        margin: 14px 0 16px 0;
        word-break: break-all;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 25px;
      }
    }
  }
  .btn {
    width: 98px;
    height: 31px;
    border-radius: 16px;
    border: 1px solid #ff6e6e;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff6e6e;
    line-height: 31px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
</style>